<script>
import Multiselect from 'vue-multiselect'
export default defineComponent({
  extends: Multiselect,
  emits: ['tag', 'select', 'update:modelValue'],
  methods: {
    select(option, key) {
      if (option.$isLabel && this.groupSelect) {
        this.selectGroup(option)
        return
      }
      if (
        this.blockKeys.indexOf(key) !== -1 ||
        this.disabled ||
        option.$isDisabled ||
        option.$isLabel
      ) {
        return
      }

      if (this.max && this.multiple && this.internalValue.length === this.max) {
        return
      }

      if (key === 'Tab' && !this.pointerDirty) return
      if (option.isTag) {
        this.$emit('tag', option.label, this.id)
        this.search = ''
        if (this.closeOnSelect && !this.multiple) {
          this.deactivate()
        }
      } else {
        const isSelected = this.isSelected(option)

        if (isSelected) {
          if (key !== 'Tab') {
            this.removeElement(option)
          }
          return
        }

        if (this.multiple) {
          this.$emit('update:modelValue', this.internalValue.concat([option]))
        } else {
          this.$emit('update:modelValue', option)
        }

        // Pass the touched key and the pointer state to the select event
        this.$emit('select', option, this.id, key, this.pointerDirty)

        if (this.clearOnSelect) {
          this.search = ''
        }
      }
      if (this.closeOnSelect) {
        this.deactivate()
      }
    },
    optionHighlight(index, option) {
      // Added for keeping the same previous behavior
      // Highlight only if pointer is dirty ( user has navigated with keyboard)
      return {
        'multiselect__option--highlight':
          index === this.pointer && this.showPointer && this.pointerDirty,
        'multiselect__option--selected': this.isSelected(option),
      }
    },
    pointerForward() {
      // Added for keeping the same previous behavior
      // Highlight the first option only after user has navigated down with keyboard
      if (!this.pointerDirty) {
        this.pointer = -1
      }

      if (this.pointer < this.filteredOptions.length - 1) {
        this.pointer++
        if (
          this.$refs.list.scrollTop <=
          this.pointerPosition - (this.visibleElements - 1) * this.optionHeight
        ) {
          this.$refs.list.scrollTop =
            this.pointerPosition -
            (this.visibleElements - 1) * this.optionHeight
        }
        if (
          this.filteredOptions[this.pointer] &&
          this.filteredOptions[this.pointer].$isLabel &&
          !this.groupSelect
        ) {
          this.pointerForward()
        }
      }
      this.pointerDirty = true
    },
  },
})
</script>
